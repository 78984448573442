import fetch from 'auth/FetchInterceptor'

const UserService = {}

UserService.create = (data) => {
	return fetch({
		url: '/account/create',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.users = () => {
	return fetch({
		url: '/account/read',
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

UserService.userbyId = (user_id) => {
	return fetch({
		url: `/account/read/?id=${user_id}`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

UserService.roles = (user_id) => {
	return fetch({
		url: `/account/read-roles?user_id=${user_id}`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

UserService.updateRole = (data) => {
	return fetch({
		url: '/account/update-roles',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.update = (data) => {
	return fetch({
		url: '/account/update',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.updateAvatar = (data) => {
	return fetch({
		url: '/account/avatar',
		method: 'PUT',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: data
	})
}

UserService.changePassword = (data) => {
	return fetch({
		url: '/account/update-password',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

export default UserService;